<script>
  import { useCookies } from "vue3-cookies";
  import { useStore } from 'vuex'
  import { mapActions} from "vuex";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import Lottie from "@/components/widgets/lottie.vue";
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  import brandModel from "@/components/widgets/store/brandModel";
  import serverConfig from '@/helpers/config';
  
  export default {
    setup() {
        const { cookies } = useCookies();
        const store = useStore();
        console.log(store)
        let tk = 'Bearer '+localStorage.getItem('tk')
        return { cookies, tk};
    },
    page: {
      title: "Gestione Marche",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Gestione Marche",
        dataEdit:{},
        item:{
            name:'',
            content:'',
            image:'',
            lang:'',
            publish:'',
            on_the_homepage:'no',
            status:'init',
        },
        items: [
          {
            text: "Gestione Marche",
            active: true,
          },
        ],
        date1: null,
        date: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        searchQuery: null,
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions2: {
          animationData: animationData1
        },
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        itemList: [
        ],
        paginated:{},
        select:'all',
        search:'',
      };
    },
    computed: {

      
      displayedPosts() {
        return this.paginate(this.itemList);
      },
      resultQuery() {
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          console.log(this.displayedPosts)
          return this.displayedPosts.filter((data) => {
            return (
              data.code.toLowerCase().includes(search) ||
              data.ragSoc.toLowerCase().includes(search) ||
              data.partitaIva.toLowerCase().includes(search) ||
              data.sigla.toLowerCase().includes(search) ||

              data.email.toLowerCase().includes(search)
            );
          });
        } else {
          return this.displayedPosts;
        }
      },
      
    },


    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    
    beforeMount() {
      this.getList();
      if (this.$store.state.auth.currentUser){
        console.log(this.$store.state.auth.currentUser.email)
      }
      
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      SearchData(){
        if (this.select != ''){
          this.getList()
        }
      },
      gotTo(name,id) {
        document.getElementById("edtclosemodal").click();
        this.$router.push({name:name, params:{id: id}})
      },
      getList(){
       axios.get(`${this.UrlServer}brands`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.itemList = response.data.results.data;
        this.paginated = response.data.results
        }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
              // this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

        });
      },
      editdata(data) {
        let result = this.itemList.findIndex(
          (index) => index._id == data._id
        );
       
       
        document.getElementById("edtidfield1").value =
          this.itemList[result]._id;
         this.dataEdit = this.itemList[result]
      },
      initItem(){
        axios.post(`${this.UrlServer}brands/register`,this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
             this.sortRes = 'desc' 
             this.getList()
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
      updatedata() {
        let result = this.itemList.findIndex(
          (o) => o._id == document.getElementById("edtidfield1").value
        );
        this.dataEdit = this.itemList[result]
        axios.put(`${this.UrlServer}brands/${document.getElementById('edtidfield1').value}`, this.dataEdit, 
         {headers: { authorization:this.tk}} )
          .then(() => {
            document.getElementById("edtclosemodal").click();
            Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
          }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          });
      },
      deletedata(event) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
            this.itemList.splice(this.itemList.indexOf(event), 1);
            try {
              axios.delete(`${this.UrlServer}brands/${event._id}`)
                .then(() => {
                  
                  this.getList()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                }).catch((error) => {
                  if (error == "Error: Request failed with status code 401")
                  {
                    this.alertSessionExp()
                  } else {
                    Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
                  }
                });
            }catch(error){
            
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          }
        });
      },
      async alertSessionExp() {
        this.clearSessionStorage()

      },
      setPages() {
        let numberOfPages = Math.ceil(this.itemList.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(itemList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return itemList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      deleteMultiple() {
        let ids_array = [];
        var items = document.getElementsByName("chk_child");
        items.forEach(function (ele) {
          if (ele.checked == true) {
            var trNode = ele.parentNode.parentNode.parentNode;
            var id = trNode.querySelector(".id a").innerHTML;
            ids_array.push(id);
          }
        });
        if (typeof ids_array !== "undefined" && ids_array.length > 0) {
          if (confirm("Are you sure you want to delete this?")) {
            var cusList = this.itemList;
            ids_array.forEach(function (id) {
              console.log(id);
              cusList = cusList.filter(function (item) {
                return item.itemId != id;
              });
            });
            this.itemList = cusList;
            document.getElementById("checkAll").checked = false;
            var itemss = document.getElementsByName("chk_child");
            itemss.forEach(function (ele) {
              if (ele.checked == true) {
                ele.checked = false
                ele.closest("tr").classList.remove("table-active");
              }
            });
          } else {
            return false;
          }
        } else {
          Swal.fire({
            title: "Please select at least one checkbox",
            confirmButtonClass: "btn btn-info",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      },

    },
    mounted() {
      var checkAll = document.getElementById("checkAll");
      if (checkAll) {
        checkAll.onclick = function () {
          var checkboxes = document.querySelectorAll(
            '.form-check-all input[type="checkbox"]'
          );
          if (checkAll.checked == true) {
            checkboxes.forEach(function (checkbox) {
              checkbox.checked = true;
              checkbox.closest("tr").classList.add("table-active");
            });
          } else {
            checkboxes.forEach(function (checkbox) {
              checkbox.checked = false;
              checkbox.closest("tr").classList.remove("table-active");
            });
          }
        };
      }
    },
    components: {
      Layout,
      PageHeader,
      lottie: Lottie,
      flatPickr,
      brandModel,
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="itemList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                
                  <h5 class="card-title mb-0">{{ $t("t-brand-list-admin") }}</h5>
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <button type="button" class="btn btn-success add-btn me-1" data-bs-toggle="modal" id="create-btn" @click="initItem">
                    <i class="ri-add-line align-bottom me-1"></i> Aggiungi Marca
                  </button>
                  <button type="button" class="btn btn-info">
                    <i class="ri-file-download-line align-bottom me-1"></i>
                    Importa
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom">
            <form>
              <div class="row g-3">  
                <div class="col-lg-6 col-md-12">
                  <div class="search-box mb-3">
                  <label for="choices-publish-status-input" class="form-label">Cerca:</label>
                    <input type="text" v-model="search" class="form-control search" placeholder="Cerca Marca in base alle voci in colonna" @input="getList()" />
                    <i class="ri-search-line search-icon pt-25"></i>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="itemTable">
                  <thead class="table-light text-muted">
                    <tr>
                
                      <th class="sort" data-sort="item_code">Codice Marca</th>
                      <th class="sort" data-sort="item_name">Nome</th>
                      <th class="sort" data-sort="item_desc">Descrizione</th>
                      <th class="sort" data-sort="action">Azioni</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of itemList" :key="index">
                     
                      <td class="id " style="display: none">
                        <a href="javascript:void(0);" class="fw-medium link-primary">{{ item._id }}</a>
                      </td>
                      <td class="code pl-5">{{ item.code }}</td>
                      <td class="Nome pl-5">{{ item.name }}</td>
                      <td class="Descrizione">{{ item.content }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Edit">
                            <brandModel :ID="item._id" :data="item" ></brandModel>
                          </li>
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Remove">
                            <a class="text-danger d-inline-block remove-item-btn" @click="deletedata(item)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <label class="pt-2">Risultati per pagina:</label>
                <div class="pl-10 pr-20">
                
                <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                  <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                </select>
                </div>

                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                    Precedenti
                  </a>
                  <ul class="pagination listjs-pagination mb-0" >
                    <li :class="{
                              active: index == page,
                              disabled: index == '...',
                            }" v-for="index in paginated.total_pages" :key="index">
                         <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                    </li>
                  
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                   Successivi
                  </a>
                </div>
                

              </div>
            </div>
            <div class="modal fade" id="showModal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Add item
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="idfield1" class="form-control" placeholder="ID" readonly />
                      </div>

                      <div class="mb-3">
                        <label for="itemname-field" class="form-label">item Name</label>
                        <input type="text" id="itemname" class="form-control" placeholder="Enter Name" required />
                      </div>

                      <div class="mb-3">
                        <label for="email-field" class="form-label">Email</label>
                        <input type="email" id="email" class="form-control" placeholder="Enter Email" required />
                      </div>

                      <div class="mb-3">
                        <label for="phone-field" class="form-label">Phone</label>
                        <input type="text" id="phone" class="form-control" placeholder="Enter Phone no." required />
                      </div>

                      <div class="mb-3">
                        <label for="date-field" class="form-label">Joining Date</label>
                        <flat-pickr v-model="date1" :config="config" class="form-control" id="joindate"></flat-pickr>
                      </div>
                      <div>
                        <label for="status-field" class="form-label">Status</label>
                        <select class="form-control" data-trigger id="statusfield">
                          <option value="">Status</option>
                          <option value="Active">Active</option>
                          <option value="Block">Block</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="closemodal" class="btn btn-light"
                          data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success" @click="adddata">Add item</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="modal fade" id="editmodal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Aggiorna anagrafica Marca
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="mb-3">
                        <label for="ragSoc" class="form-label">Codice Marca</label>
                        <input type="text" id="ragSoc" v-model="dataEdit.code" class="form-control" placeholder="Inserisci Codice"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="partitaIva" class="form-label">Nome</label>
                        <input type="text" id="partitaIva" v-model="dataEdit.name" class="form-control" placeholder="Inserisci Nome Marca"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="customername-field" class="form-label">Descrizione</label>
                        <textarea type="text" id="codeDest" v-model="dataEdit.content" class="form-control" placeholder="Inserisci Descrizione"
                          required ></textarea>
                      </div> 
                      <div class="mb-3">
                        <a href="javascript:void(0)" @click="gotTo('brands-detail', dataEdit._id)">
                            >> Impostazioni avanzate
                        </a>               
                      </div>

                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                        <button type="button" class="btn btn-success" @click="updatedata">
                          Aggiorna
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>